@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/constants/size.module';

.root {
  height: calc(100vh - $H_Mobile_Nav);
  background-color: $N20;

  .action {
    display: flex;
    align-items: center;
    align-self: stretch;
    height: 60px;
    padding: 16px;
  }

  .main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    align-self: stretch;
    background-color: $N20;

    .header {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
      align-self: stretch;
      padding: 0 24px;
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 32px;
      align-items: flex-start;
      align-self: stretch;
      padding: 0 20px 80px;
      background-color: $N20;

      .section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        justify-content: center;
      }

      .section1 {
        gap: 12px;
      }

      .section2 {
        gap: 12px;
      }

      .section3 {
        gap: 12px;
        padding-bottom: 20px;

        .balance {
          display: flex;
          flex-direction: column;
          align-items: center;
          align-self: stretch;
          padding: 12px 24px;
          background-color: $N30;
          border-radius: 8px;

          .balanceTitle {
            @include Body1;
            display: flex;
            align-items: center;
            align-self: stretch;
            justify-content: space-between;
            cursor: pointer;

            dd {
              display: flex;
              gap: 4px;
              align-items: center;

              svg {
                transition: transform 0.3s ease;
              }

              &.open svg {
                transform: rotate(180deg);
              }
            }
          }

          .balanceBody {
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: flex-start;
            align-self: stretch;
            max-height: 0;
            margin-top: 0;
            overflow: hidden;
            opacity: 0;
            transition: all 0.3s ease-in-out;

            &.open {
              max-height: 400px; // 5개 기준 300 약간 넘음
              padding-bottom: 20px;
              margin-top: 24px;
              opacity: 1;
            }
          }
        }
      }

      .section4 {
        gap: 8px;
      }
    }

    .startButton {
      display: flex;
      width: 100%;
      padding: 0 20px 40px;
      background-color: $N20;
    }
  }
}

.banner {
  @include Body2;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  align-self: stretch;
  padding: 12px;
  background-color: $N40;
  border-radius: 8px;

  .strong {
    color: $Yellow;
  }
}
