@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.rowContainer {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.rowListWrapper {
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
  max-height: 440px;
  margin-top: 8px;
  overflow: auto;
}

.root {
  display: flex;
  flex-direction: column;
  padding: 40px;

  .noResult {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px 0;
    color: $N50;

    p:nth-child(1) {
      @include Headline2;
      margin-bottom: 8px;
    }

    p:nth-child(2) {
      @include Label1;
    }
  }
}

.confirmModalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
}

.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 440px;
}
