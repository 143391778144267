@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.container {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  background: $N20;
  border: 1px solid $N50;
  border-radius: 8px;
}

.modalTitleWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 17px 18px 17px 32px;
}

.modalTitle {
  @include Label1;
  color: $N80;
}

.closeIcon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.contentWrapper {
  @include Body1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px;
  border-top: 1px solid $N40;

  p:last-child {
    @include Body2;
    color: $N50;
  }
}

.buttonWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}
