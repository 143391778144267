@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  width: 100%;
  padding: 16px 0;
  background-color: $N30;
  border-radius: 12px;

  .top {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    justify-content: space-between;
    padding: 0 20px;

    .left {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
      width: 100%;
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
    }

    .tags {
      display: flex;
      gap: 6px;
      align-items: center;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    align-self: stretch;

    .section1 {
      display: flex;
      align-items: flex-start;
      align-self: stretch;
      padding: 0 20px;
    }

    .section2 {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
      align-self: stretch;
      padding: 0 20px;
    }
  }
}
