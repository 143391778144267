@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/theme';
@import '@/styles/constants/size.module';

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: calc(100vh - $H_Mobile_Nav);

  .loading {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .noStrategy {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: $N50;
    text-align: center;

    .text {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      p:first-child {
        @include Label2;
      }

      p:last-child {
        @include Body2;
      }
    }
  }
}

.strategies {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 20px;
}
