@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

// TODO: 나중에 공통 속성 묶음 처리

.scoreTooltipSheet {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  align-self: stretch;

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    align-self: stretch;

    .header {
      @include Label1;
      color: $N80;
    }

    .body {
      @include Caption;
      align-self: stretch;
      color: $N50;
    }
  }
}

.dcaTooltipSheet {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  align-self: stretch;

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .header {
      @include Label1;
      color: $N80;
    }

    .body {
      @include Body2;
      color: $N50;
    }
  }
}

.riskTooltipSheet {
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
    align-self: stretch;

    .top {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
      align-self: stretch;

      .header {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
        align-self: stretch;
        justify-content: center;

        .title {
          @include Label1;
          align-self: stretch;
          color: $N80;
        }

        .description {
          @include Caption;
          align-self: stretch;
          color: $N50;
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        align-self: stretch;

        .row {
          @include Caption;
          display: flex;
          gap: 12px;
          align-items: center;
          align-self: stretch;
          color: $N50;
        }
      }
    }

    .buttons {
      display: flex;
      gap: 16px;
      align-self: stretch;
    }
  }
}

.minPrincipalTooltipSheet {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
  align-self: stretch;
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    align-self: stretch;
  }

  .header {
    @include Label1;
  }

  .body {
    @include Body2;
    display: flex;
    flex-direction: column;
    gap: 36px;
    color: $N50;

    .example {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }
}

.paperRobotTooltipSheet {
  width: 100%;

  .container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    align-self: stretch;

    .content {
      display: flex;
      flex-direction: column;
      gap: 40px;
      align-items: flex-start;
      align-self: stretch;

      .main {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
        align-self: stretch;

        .header {
          @include Label1;
        }

        .body {
          @include Body2;
        }
      }

      .caption {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
        align-self: stretch;

        .row {
          @include Caption;
          display: flex;
          gap: 8px;
          align-items: center;
          align-self: stretch;
          color: $N50;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            padding: 2px;
          }
        }
      }
    }
  }
}

.logoutSheet {
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
    align-self: stretch;
  }

  .header {
    @include Label1;
    display: flex;
    gap: 4px;
    align-self: stretch;
  }

  .buttons {
    display: flex;
    gap: 16px;
    align-self: stretch;
  }
}

.withdrawSheet {
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
    align-self: stretch;

    .header {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-self: stretch;
      justify-content: center;

      .subTitle {
        @include Caption;
        color: $N50;
      }

      .title {
        @include Label1;
        color: $N80;
      }
    }

    .buttons {
      display: flex;
      gap: 16px;
      align-self: stretch;
    }
  }
}

.strategiesSheet {
  // MEMO: TB-4305
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
  justify-content: center;
  width: 100%;
  padding-top: 12px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    align-self: stretch;
    padding: 0 4px;

    .description {
      @include Body2;
      color: $N50;
    }
  }

  .body {
    ul {
      display: flex;
      flex-direction: column;
      gap: 16px;

      // MEMO: TB-4305, 위 높이에서 36px(margin만큼 뺀 것)
      max-height: calc(100vh - 276px);
      padding-bottom: 40px;
      overflow-y: auto;
    }

    .noResult {
      display: flex;
      flex-direction: column;
      gap: 32px;
      align-items: center;
      justify-content: center;
      color: $N50;

      .text {
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-align: center;

        p:nth-child(1) {
          @include Label1;
        }

        p:nth-child(2) {
          @include Body2;
        }
      }
    }
  }
}

.agreementSheet {
  max-height: 80vh;
  overflow-y: auto;

  .title {
    @include Title2;
    margin-bottom: 16px;
  }

  .agreeRow {
    @include Body2;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 16px 0;
  }

  .buttons {
    display: flex;
    gap: 16px;
    width: 100%;
  }
}

.accountSelectionSheet {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    @include Body2;
    color: $N50;

    .title {
      @include Title2;
      color: $N80;
    }
  }

  .description {
    @include Body2;
    color: $N50;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 48px;
  }
}

.marketSelectionSheet {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  align-self: stretch;

  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    align-self: stretch;

    .description {
      @include Body2;
      color: $N50;
    }
  }
}

.paperTradingSheet {
  width: 100%;

  .container {
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;
    align-self: stretch;

    .content {
      display: flex;
      flex-direction: column;
      gap: 48px;
      align-items: flex-start;
      align-self: stretch;

      .title {
        @include Label1;
      }

      .caption {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
        align-self: stretch;

        .row {
          @include Caption;
          display: flex;
          gap: 8px;
          align-items: center;
          align-self: stretch;
          color: $N50;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 16px;
      align-items: flex-start;
      align-self: stretch;
      justify-content: center;
    }
  }

  .header {
    @include Label1;
    display: flex;
    gap: 4px;
    align-self: stretch;
  }

  .buttons {
    display: flex;
    gap: 16px;
    align-self: stretch;
  }
}

.robotStartNoticeSheet {
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    align-self: stretch;
    justify-content: center;
  }

  .header {
    @include Label1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    align-self: stretch;

    path:first-child {
      fill: $P40;
    }
  }

  .body {
    @include Body2;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
    align-self: stretch;
    padding: 16px;
    background-color: $N40;
    border-radius: 12px;
  }
}

.robotStartTutorialSheet {
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    align-self: stretch;
    justify-content: center;
  }

  .header {
    @include Label1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-self: stretch;

    .p1 {
      @include Caption;
      color: $P50;
    }

    .p2 {
      @include Label1;
      color: $N80;
    }
  }
}

.pushNotificationNoticeSheet {
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
  align-self: stretch;
  width: 100%;

  .contentWrap {
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;
    width: 100%;

    .content1 {
      @include Label1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
      width: 100%;

      .p1 {
        @include Label1;
      }

      .p2 {
        @include Body2;
        color: $N50;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;
  }
}

.installAppSheet {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  align-self: stretch;
  padding: 20px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    align-self: stretch;

    .text {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      align-self: stretch;
      text-align: center;

      .p1 {
        @include Title1;
      }

      .p2 {
        @include Body2;
        color: $N50;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    align-self: stretch;
    justify-content: center;

    .dismiss {
      @include Body2;
      color: $N50;
      text-decoration: underline;
    }
  }
}

.sortOrderSheet {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.strategyFilterSheet {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
  align-self: stretch;

  .wrap {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
    align-self: stretch;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;

    .sectionTitle {
      @include Label2;
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .subtext {
      @include Caption;
      color: $N50;
    }

    .filterButtons {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      align-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
    }
  }
}

.balanceNoticeSheet {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  align-self: stretch;

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    align-self: stretch;

    .text {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      align-self: stretch;
      justify-content: center;

      .p1 {
        @include Title2;
      }

      .p2 {
        @include Body2;
        color: $N50;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;
  }
}
