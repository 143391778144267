@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/responsive';

$table_grid: 6fr 3fr 3fr 2fr;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  .banner {
    position: relative;
    width: 100%;
    aspect-ratio: 5.5 / 1;

    .textContainer {
      @include Headline1;
      position: absolute;
      top: 55px;
      left: 60px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: $N10;
    }

    .pageTitle {
      @include Display2;
    }

    .description {
      @include Body1;
    }
  }

  .contents {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 1320px;
    padding: 0;

    .controlBar {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: flex-end;

      .toggle {
        display: flex;
        gap: 8px;

        .text {
          @include Body2;
        }
      }

      .sorting {
        @include Label1;
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        color: $N50;
      }
    }

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
    }

    .strategyList {
      display: flex;
      flex-direction: column;

      .headerRow {
        display: grid;
        grid-template-columns: $table_grid;
        height: 48px;
        padding: 0 20px;
        background-color: $N20;
        border-top: 1px solid $N40;
        border-bottom: 1px solid $N40;

        > div {
          @include Body1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $N80;
        }
      }

      .row {
        .link {
          display: grid;
          grid-template-columns: $table_grid;
          align-items: center;
          height: 72px;
          padding: 0 20px;
          border-bottom: 1px solid $N40;
        }

        &:hover {
          background-color: $N20;
        }

        .column {
          @include Label1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $N80;
        }

        .column1 {
          @include Label1;
          gap: 8px;
          justify-content: flex-start;

          .title {
            display: flex;
            gap: 8px;
            align-items: center;
          }

          .author {
            @include Body1;
            color: $N50;
          }
        }

        .column2 {
          @include Headline2;
        }

        .column4 {
          color: $N50;
        }
      }
    }
  }
}
