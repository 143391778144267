@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.startModal {
  .contents {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 40px;

    dl {
      > div {
        display: grid;
        grid-template-columns: 120px auto;
        gap: 16px;
        align-items: center;
        padding: 8px 0;
        border-bottom: 1px solid $N40;
      }

      dt {
        @include Body2;
        color: $N50;
      }

      dd {
        @include Label1;
      }

      .currentAsset {
        color: $N50;
      }
    }
  }

  .warnings {
    p {
      @include Body1;
      color: $N50;
    }
  }
}
