@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.root {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  &.small {
    @include Caption;
    padding: 4px 8px;
    border-radius: 6px;
  }

  &.large {
    @include Label2;
    padding: 8px 12px;
    border-radius: 8px;
  }

  &[class*='neutral'] {
    color: $N80;
  }

  &[class*='primary'] {
    color: $P50;
  }

  &[class*='green'] {
    color: $Green;
  }

  &[class*='yellow'] {
    color: $Yellow;
  }

  &.neutral1,
  &.primary1,
  &.green1,
  &.yellow1 {
    background-color: $N30;
  }

  &.neutral2,
  &.primary2,
  &.green2,
  &.yellow2 {
    background-color: $N20;
  }

  &.primary3 {
    color: $P70;
    background-color: $P20;
  }
}
