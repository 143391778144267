@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/theme';

.root {
  section.top {
    .breadcrumb {
      @include Label1;
      margin-top: 24px;
      color: $N50;
    }

    .notice {
      @include Body1;
      margin-top: 8px;
      color: $N50;
    }
  }

  section.box {
    border: 1px solid $N40;
    border-radius: 8px;

    h2 {
      @include Label1;
      padding: 16px 32px;
      color: $N80;
      border-bottom: 1px solid $N40;
    }

    .settingContents {
      padding: 32px;

      h3 {
        @include Body2;
        margin-bottom: 8px;
        color: $N50;
      }

      .selectedStrategy {
        display: flex;
        gap: 16px;
        align-items: center;

        input {
          width: 400px;
          height: 40px;
          padding: 0 16px;
          color: $N50;
          background-color: inherit;
          border: 1px solid $N50;
          border-radius: 4px;

          &::placeholder {
            color: $N50;
          }
        }
      }

      &.tradeSettings {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .tradeSetting {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        input {
          @include Body1;
          width: 400px;
          height: 48px;
          padding: 0 8px 0 16px;
          color: $N80;
          background-color: $N20;
          border: none;

          &::placeholder {
            color: $N40;
          }
        }

        .inputWrapper {
          display: flex;
          align-items: center;
          width: 400px;
          height: 40px;
          padding-right: 16px;
          background-color: $N30;

          input {
            width: 100%;
            height: 100%;
            color: $N80;
            background-color: inherit;
            border: none;

            &::placeholder {
              color: $N50;
            }
          }

          .unit {
            color: $N50;
            white-space: nowrap;
          }
        }

        .warning {
          @include Body2;
          color: $P50;
        }
      }
    }
  }

  section.bottom {
    display: flex;
    justify-content: center;
  }
}
