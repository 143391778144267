@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/theme';

.root {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 24px;

  .pageButtons {
    display: flex;

    .pageButton {
      @include Body1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      padding: 10px;
      color: $N50;

      &.active {
        color: $N80;
        background-color: $N40;
        border-radius: 2px;
      }
    }
  }

  .nextButton,
  .prevButton {
    @include Body1;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;

    &.disabled {
      color: $N40;
    }
  }
}
