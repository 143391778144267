@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.root {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  &.active {
    color: $N80;
  }

  &.border {
    border-bottom: 3px solid $N50;
  }

  &.active.border {
    border-bottom: 3px solid $N80;
  }

  &.large {
    @include Label1;
    padding: 16px 24px;
  }

  &.small {
    @include Label2;
    padding: 12px;
  }
}
