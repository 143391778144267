@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.root {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  align-self: stretch;

  &.large {
    @include Title2;
  }

  &.medium {
    @include Label1;
  }

  &.small {
    @include Label2;
  }
}
