@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.root {
  display: flex;
  flex: 0 0 48px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  background-color: $N20;
  border: 1px solid $N40;
  border-radius: 2px;

  & div {
    box-sizing: border-box;
  }

  &:hover {
    background-color: $N30;
  }
}

.headerRoot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
}

.headerColumn {
  @include Body2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $N50;
}

.createdAtColumn {
  @extend .headerColumn;
  flex: 0.8;
}

.createdAtRow {
  @include Body2;
  flex: 0.8;
  color: $N50;
}

.createdAtWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 24px;
}

.titleColumn {
  @extend .headerColumn;
  flex: 2;
}

.titleRow {
  @include Body2;
  display: flex;
  flex: 2;
  align-items: center;
  color: $N80;
}

.titleWrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  padding-left: 20px;
}

.rorColumn {
  @extend .headerColumn;
  flex: 0.7;
}

.rorRow {
  @include Body2;
  display: flex;
  flex: 0.7;
  align-items: center;
  justify-content: center;
  color: $N80;
}

.inProgressTag {
  color: $N50;
}

.mddColumn {
  @extend .headerColumn;
  flex: 0.7;
}

.mddRow {
  @include Body2;
  display: flex;
  flex: 0.7;
  align-items: center;
  justify-content: center;
  color: $N80;
}

.backtestingRangeColumn {
  @extend .headerColumn;
  flex: 1.5;
}

.backtestingRangeRow {
  @include Body2;
  display: flex;
  flex: 1.5;
  align-items: center;
  justify-content: center;
  color: $N80;
}
