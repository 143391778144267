@import '@/styles/constants/colors.module';
@import '@/styles/mixins/responsive';

.checkBox {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: 1px solid $N50;
  border-radius: 50%;

  @include mobile {
    width: 24px;
    height: 24px;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 12px;
    content: '';
    border-top: none;
    border-right: 3px solid $N80;
    border-bottom: 3px solid $N80;
    border-left: none;
    opacity: 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:checked {
    background-color: $P40;
    border: none;
  }

  &:checked::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 12px;
    content: '';
    border-right: 3px solid $N80;
    border-bottom: 3px solid $N80;
    opacity: 1;
    transform: translate(-50%, -50%) rotate(45deg);

    @include mobile {
      width: 6px;
      height: 10px;
    }
  }
}
