@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/theme';
@import '@/styles/constants/size.module';

.root {
  display: flex;
  align-items: center;
  border-radius: 100%;

  button {
    background: transparent;
    border-radius: 100%;
  }
}
