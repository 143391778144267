@import '@/styles/constants/colors.module';

.root {
  align-self: stretch;
  border: none;

  &.fill {
    height: 16px;
  }

  &.line {
    height: 1px;
  }

  &.black {
    background-color: $Black;
  }

  &.n10 {
    background-color: $N10;
  }

  &.n30 {
    background-color: $N30;
  }

  &.n50 {
    background-color: rgba($N50, 0.2);
  }
}
