@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.root {
  @include Label1;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  color: $N50;
}
