.root {
  &.large {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 10px;
    align-items: center;
    width: 42px;
    height: 22px;
    padding-bottom: 4px;
  }

  &.small {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 10px;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: 4px 0;
  }
}
