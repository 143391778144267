@import '@/styles/constants/colors.module';
@import '@/styles/mixins/theme';

$overlay_z_index: 1;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $overlay_z_index;
  width: 100%;
  height: 100%;
  background-color: rgba(black, 70%);
}

.bottomSheet {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $overlay_z_index + 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  width: 100%;
  padding: 28px 20px 40px;
  overflow-y: auto;
  background-color: $N10;
  border-radius: 12px 12px 0 0;

  .action {
    display: flex;
    gap: 10px;
    align-items: center;
    align-self: stretch;
    justify-content: flex-end;
  }
}
