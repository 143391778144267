@import '@/styles/constants/colors.module';

.selectionModal {
  display: flex;
  flex-direction: column;
  width: 530px;
  background: $N10;
  border: 1px solid $N50;
  border-radius: 8px;
}

.modalTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px 18px 30px;
  border-bottom: 1px solid $N40;
}

.modalTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 120%;
  color: $N80;
}

.closeIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 28px;
}

.cancelButton {
  flex: 1;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  color: $P70;
  text-align: center;
  cursor: pointer;
  border: 1px solid $P70;
  border-radius: 4px;
}

.confirmButton {
  flex: 1;
  padding: 8px 0;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  color: $N20;
  text-align: center;
  cursor: pointer;
  background: $P70;
  border-radius: 4px;
}
