@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/responsive';

.description {
  margin-bottom: 24px;

  @include mobile {
    @include Body2;
    margin-bottom: 16px;
    color: $N50;
  }
}

.termContainer {
  height: 300px;
  padding: 16px;
  overflow-y: auto;
  line-height: 1.6;
  color: $N50;
  background-color: $N20;
  border: 1px solid $N30;
  border-radius: 4px;

  .sectionTitle {
    @include Label1;

    @include mobile {
      @include Body2;
    }
  }

  p {
    margin: 16px 0;
  }

  ol {
    margin: 16px 0 16px 16px;

    li {
      padding-left: 4px;
      margin-bottom: 8px;
      margin-left: 4px;
      list-style: decimal;
    }
  }

  @include mobile {
    @include Body2;
    height: 200px;
  }
}

.agreeRow {
  display: flex;
  gap: 18px;
  align-items: center;
  margin-top: 24px;
}
