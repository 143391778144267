@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.root {
  display: inline-flex;
  align-items: flex-start;
  white-space: nowrap;

  &.vertical {
    flex: 1 0 0;
    flex-direction: column;
  }

  &.horizontal {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  // MEMO: 데스크톱뷰 싱크 맞추다가 임시로 추가함, 나중에 재정의 필요
  &.centerAligned {
    display: flex;
    flex: 1 0 0;
    gap: 8px;
    align-items: center;
    padding: 24px 32px;
  }

  .headline1 {
    @include Headline1;
  }

  .headline2 {
    @include Headline2;
  }

  .title2 {
    @include Title2;
  }

  .label1 {
    @include Label1;
  }

  .label2 {
    @include Label2;
  }

  .body1 {
    @include Body1;
  }

  .body2 {
    @include Body2;
  }

  .caption {
    @include Caption;
  }

  .term {
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
    align-items: center;
    color: $N50;

    &.withTooltip {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .value {
    display: flex;
    gap: 8px;
    align-content: center;
    align-items: center;
    justify-content: center;
    color: $N80;

    &.red {
      color: $Red;
    }

    &.n50 {
      color: $N50;
    }

    &.label2,
    &.caption {
      gap: 4px;
    }

    .link {
      text-decoration: underline;
    }
  }
}
