@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.root {
  padding: 32px;
}

.description {
  @include Body1;
}

.tipContainer {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  margin-top: 16px;
}

.tipBox {
  @include Caption;
  padding: 0 10px;
  color: $P70;
  border: 1px solid $P70;
  border-radius: 4px;
}

.tipDescription {
  @include Body2;
  margin-top: 10px;
  color: $P70;
}

.buttonsWrapper {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.row {
  display: flex;
  align-items: center;
  height: 40px;
}

.rowLabel {
  @include Body2;
  width: 120px;
  color: $N50;
}

.title {
  @include Title2;
  color: $N80;
}

.rowText {
  @include Body1;
  color: $N80;
}
