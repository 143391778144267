@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/theme';
@import '@/styles/constants/size.module';

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  padding: 16px 20px 20px;
  background-color: $N30;
  border-radius: 12px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    align-self: stretch;

    .tagWithDate {
      display: flex;
      flex-wrap: wrap;
      row-gap: 10px;
      align-content: center;
      align-items: center;
      align-self: stretch;
      justify-content: space-between;

      .tags {
        display: flex;
        gap: 8px;
        align-items: center;
      }

      .createdAt {
        @include Caption;
        color: $N50;
      }
    }

    .period {
      @include Caption;
      color: $N50;
    }
  }

  .body {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    align-content: center;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;

    > * {
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
