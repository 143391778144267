@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/theme';

.text {
  display: inline-block;
  padding: 2px 8px;
  white-space: nowrap;
  background-color: $N40;
  border-radius: 2px;
}

.medium {
  @include Body1;
}

.small {
  @include Caption;
}

.primary {
  color: $P70;
}

.secondary {
  color: $S60;
}

.neutral {
  color: $N80;
}
