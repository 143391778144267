@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.tab1 {
  display: inline-flex;
  gap: 4px;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid $N40;
}

.tab2 {
  display: flex;
  align-items: center;
  width: 100%;

  > * {
    flex-grow: 1;
  }
}
