@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/theme';
@import '@/styles/constants/size.module';

.root {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - $H_Mobile_Nav);
  background-color: $N10;

  .main {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    align-self: stretch;

    &.disabled {
      pointer-events: none;
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 48px;
      align-items: flex-start;
      align-self: stretch;
      padding: 0 20px 120px;

      .strategies {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        align-self: stretch;
        width: 100%;

        .card {
          width: 100%;
        }
      }

      .section1 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        align-self: stretch;
        padding-top: 24px;

        .contentWrap {
          display: flex;
          gap: 8px;
          align-items: center;
          align-self: stretch;
          padding-right: 20px;
          overflow-x: auto;
          overflow-y: hidden;
          -webkit-overflow-scrolling: touch;
          scrollbar-width: none;
          -ms-overflow-style: none;

          .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 16px 20px;
            background-color: $N20;
            border-radius: 12px;
          }
        }
      }

      .section2 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-self: stretch;
      }

      .section3 {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
        align-self: stretch;

        .sortWithFilter {
          display: flex;
          flex-wrap: wrap;
          row-gap: 4px;
          align-content: center;
          align-items: center;
          align-self: stretch;
          justify-content: space-between;
          padding: 0 4px;

          .left {
            @include Caption;
            display: flex;
            gap: 4px;
            align-items: center;
            color: $N50;
          }

          .right {
            display: flex;
            gap: 4px;
            align-items: center;

            .filterAmount {
              @include Caption;
              display: flex;
              gap: 10px;
              align-items: center;
              justify-content: center;
              padding: 2px 8px;
              background: $N20;
              background-color: $N40;
              border-radius: 100px;
              border-radius: 12px;
            }
          }
        }

        .noResult {
          display: flex;
          flex: 1 0 0;
          flex-direction: column;
          gap: 4px;
          align-items: center;
          align-self: stretch;
          justify-content: center;
          padding: 40px;

          .noResultTitle {
            @include Label2;
          }

          .noResultDescription {
            @include Caption;
            color: $N50;
          }
        }
      }
    }
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 40px;
  }
}
