@import '@/styles/constants/colors.module';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html,
body {
  color: $N80;
  background-color: $N10;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

img {
  border: 0;
}

a {
  text-decoration: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0%);
}

input,
button {
  appearance: none;
}

button {
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  user-select: none;
  background: transparent;
  border: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0%);

  &:disabled {
    cursor: not-allowed;
  }
}
