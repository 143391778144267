/* stylelint-disable property-no-unknown */

$Black: #000000;
$White: #ffffff;

// Primary
$P80: #8cf2f2;
$P70: #51edfb;
$P50: #03d0e2;
$P40: #05afd1;
$P30: #0f6b8a;
$P20: #0e394e;

// Secondary
$S60: #ff94b4;

// Neutral
$N90: #f1f3f3;
$N80: #cfd0d3;
$N50: #828c97;
$N40: #2a2d32;
$N30: #21252b;
$N20: #17191c;
$N10: #111213;

// Status
$Green: #1ed45a;
$Yellow: #ffa938;
$Red: #ff6363;

// Chart
$ChartRed: #f23645;
$ChartBlue: #4285f4;

// Modal
$IntervieweeRecruitModalBG: #463be3;

/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:export {
  black: $Black;
  white: $White;
  n10: $N10;
  n20: $N20;
  n30: $N30;
  n40: $N40;
  n50: $N50;
  n80: $N80;
  p30: $P30;
  p40: $P40;
  p70: $P70;
  s60: $S60;
  chartRed: $ChartRed;
  chartBlue: $ChartBlue;
}
