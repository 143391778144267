@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.root {
  @include Body1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  width: 100%;

  .item {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    padding: 12px 4px;

    &.selected {
      color: $P50;
    }
  }
}
