@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.root {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  &.readOnly {
    gap: 12px;
  }

  .label {
    @include Body2;
    color: $N50;
  }

  .inputBox {
    display: flex;
    gap: 8px;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    padding: 16px 24px;

    &.readOnly {
      background-color: $N30;
      border-radius: 8px;
    }
  }

  .inputBoxWithFeedback {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    align-self: stretch;

    .textBox {
      display: flex;
      gap: 8px;
      align-items: center;
      align-self: stretch;
      padding: 16px 8px;
      border-bottom: 2px solid $N80;

      &.noValue {
        color: $N50;
        border-bottom-color: $N50;
      }
    }

    .feedback {
      @include Body2;

      &.default {
        color: $N50;
      }

      &.error {
        color: $S60;
      }

      &.success {
        color: $P50;
      }
    }
  }

  .input {
    @include Body1;
    display: flex;
    flex: 1 0 0;
    align-items: center;
    color: $N80;
    background-color: inherit;
    border: none;

    &::placeholder {
      color: $N50;
    }
  }

  .subText {
    @include Body1;

    &.noValue {
      color: $N50;
    }
  }
}
